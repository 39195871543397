import React from 'react';
import logo from './images/default-img.png';
import {Link, BrowserRouter, Route, Routes, useParams } from 'react-router-dom';
import { getAuth, sendSignInLinkToEmail } from "firebase/auth";
import { initializeApp } from 'firebase/app';
import AdminPage from './AdminPage.js';
import { getFirestore } from "firebase/firestore";
import { Card, CardContent, Button, TextField } from "@mui/material";

const AdminLogin = () => {
    const [val, setVal] = React.useState();
    const [email, setEmail] = React.useState();

    const firebaseConfig = {
        apiKey: "AIzaSyByx_HtrqJm-cI8F1c6kYI9arYHFiLdDbA",
        authDomain: "buzz-studios-7f814.firebaseapp.com",
        databaseURL: "https://buzz-studios-7f814-default-rtdb.firebaseio.com",
        projectId: "buzz-studios-7f814",
        storageBucket: "buzz-studios-7f814.appspot.com",
        messagingSenderId: "103909984010",
        appId: "1:103909984010:web:6b15701bb800c731cd1cf3",
        measurementId: "G-GY05FJ1M7L"
    };

    const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: 'https://films.buzzstudios.org/myfilms',
    // This must be true.
    handleCodeInApp: true,
    };

    const sendEmailLink = (email) => {
        const app = initializeApp(firebaseConfig);
        const auth = getAuth(app);

        sendSignInLinkToEmail(auth, email, actionCodeSettings)
        .then(() => {
            window.localStorage.setItem('emailForSignIn', email);
            alert("Please check your email for a sign-in link.");
            window.close();
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            alert(errorMessage);
        });
    };

    return (
        <>
            <BuzzHeader/>
            <br></br>
            <Card variant="outlined" sx={{width: 500, margin: "0 auto"}}>
                <CardContent style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
                    <p style={{color: "black", fontSize: 25, margin: 0, fontFamily: "Lucida Sans"}}>Admin Login</p>
                    <TextField value={email} onChange={(event) => {setEmail(event.target.value);}} id="outlined-basic" label="Email" variant="outlined" sx={{width: 350, margin: 1}} /> 
                    <Button onClick={() => sendEmailLink(email)} variant="contained" color="warning" style={{fontSize: 20, marginTop: 10}}>send login link</Button>
                </CardContent>
            </Card>
        </>
    );
}

const BuzzHeader = () => {
    return (
        <div className="header">
            <div style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
            <Link to="/">
                <img src={logo} alt="Buzz Studios Filmmaking Club" />
            </Link>
            </div>
        </div>
    )
};

export default AdminLogin;